import restApiSlice from "../../services/rest-api/slice"

export const invoicesRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaidInvoices: builder.query({
      query: () => ({ url: "/invoices/paid", method: "GET" }),
      providesTags: ["PaidInvoices"],
      transformResponse: (response) =>
        response.data.map((invoice) => {
          return {
            ...invoice,
            summary: {
              ...invoice.summary,
              amountPaid: invoice.summary?.amountPaid !== undefined ? invoice.summary.amountPaid : 0,
              amountOutstanding: invoice.summary?.amountOutstanding !== undefined ? invoice.summary.amountOutstanding : invoice.summary.invoice.total,
            },
          }
        }),
    }),
    getUnpaidInvoices: builder.query({
      query: () => ({ url: "/invoices/pending", method: "GET" }),
      providesTags: ["UnpaidInvoices"],
      transformResponse: (response) =>
        response.data.map((invoice) => {
          return {
            ...invoice,
            summary: {
              ...invoice.summary,
              amountPaid: invoice.summary.amountPaid >= 0 ? invoice.summary.amountPaid : 0,
              amountOutstanding: invoice.summary.amountOutstanding >= 0 ? invoice.summary.amountOutstanding : invoice.summary.invoice.total,
            },
          }
        }),
    }),
    getInvoice: builder.query({
      query: (invoiceId) => ({ url: `/invoices/${invoiceId}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getInvoicesCandidatesCompanies: builder.query({
      query: () => ({ url: "/invoices/candidates/companies", method: "GET" }),
      providesTags: ["InvoicesCandidates"],
      transformResponse: (response) => response.data,
    }),
    getInvoicesCandidatesCompany: builder.query({
      query: ({ companyId, currency }) => ({ url: `/invoices/candidates/companies/${companyId}/${currency}`, method: "GET" }),
      providesTags: ["InvoicesCandidates"],
      transformResponse: (response) => response.data,
    }),
    createCompaniesInvoice: builder.mutation({
      query: () => ({ url: "/invoices/organizations", method: "POST", body: undefined }),
      invalidatesTags: ["InvoicesCandidates", "UnpaidInvoices"],
      transformResponse: (response) => response.data,
    }),
    createCompanyInvoice: builder.mutation({
      query: ({ companyId, currency }) => ({ url: `/invoices/organizations/${companyId}/${currency}`, method: "POST", body: undefined }),
      invalidatesTags: ["InvoicesCandidates", "UnpaidInvoices"],
      transformResponse: (response) => response.data,
    }),
    createCredits: builder.mutation({
      query: (payload) => ({ url: "/credits", method: "POST", body: payload }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["InvoicesCandidates"],
    }),
    updateCredit: builder.mutation({
      query: (payload) => ({ url: `/credits/${payload.creditId}`, method: "PATCH", body: payload }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["InvoicesCandidates"],
    }),
    deleteCredit: builder.mutation({
      query: ({ creditId, companyId }) => ({ url: `/credits/${creditId}/${companyId}`, method: "DELETE" }),
      providesTags: ["InvoicesCandidates"],
      transformResponse: (response) => response.data,
    }),
    createPaymentRecord: builder.mutation({
      query: ({ id, payload }) => ({ url: `/payments/manual/invoice/${id}`, method: "POST", body: payload }),
      invalidatesTags: ["UnpaidInvoices", "PaidInvoices"],
      transformResponse: (response) => response.data,
    }),
    sendEmailInvoice: builder.mutation({
      query: ({ payload }) => ({ url: `/invoices/email`, method: "POST", body: payload }),
      invalidatesTags: ["UnpaidInvoices"],
      transformResponse: (response) => response.data,
    }),
    chargePaymentMethod: builder.mutation({
      query: ({ id, payload }) => ({ url: `/payments/charge/invoice/${id}`, method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
    getInvoicePreview: builder.mutation({
      query: ({ companyId, currency }) => ({
        url: `/invoices/preview/${companyId}/${currency}`,
        method: "GET",
      }),
    }),
  }),
})

export const {
  useCreateCreditsMutation,
  useUpdateCreditMutation,
  useDeleteCreditMutation,
  useCreatePaymentRecordMutation,
  useCreateCompanyInvoiceMutation,
  useCreateCompaniesInvoiceMutation,
  useGetInvoiceQuery,
  useGetInvoicesCandidatesCompaniesQuery,
  useGetInvoicesCandidatesCompanyQuery,
  useGetPaidInvoicesQuery,
  useGetUnpaidInvoicesQuery,
  useSendEmailInvoiceMutation,
  useChargePaymentMethodMutation,
  useGetInvoicePreviewMutation,
} = invoicesRestApiSlice
