import PropTypes from "prop-types"
import React, { useEffect } from "react"

// MUI
import { FormControl, FormControlLabel, Checkbox, Box, FormGroup } from "@mui/material"

// Formik
import { useFormik } from "formik"

// Mutations
import { useUpdateCompanyMutation } from "../../../../../../../../../services/company/slice"

function CompanyFeatureFlagsDrawer({ company, onSubmitEvent, setShowCompanyFeatureFlagsDrawer, refetchCompany }) {
  const featureFlagsTypes = [
    {
      id: 1,
      type: "thirdPartyBilling",
      name: "Third Party Billing",
    },
    {
      id: 2,
      type: "isEmailLabel",
      name: "Label Emailing Feature",
    },
    {
      id: 3,
      type: "isBillingSenderAllowed",
      name: "Billing Sender",
    },
    {
      id: 4,
      type: "orderManagement",
      name: "Order Management System",
    },
    {
      id: 4,
      type: "exportAllInvoices",
      name: "Export All Invoices",
    },
    // Add more feature flag types as needed
  ]

  const [setCompanyDetails] = useUpdateCompanyMutation()

  const formik = useFormik({
    initialValues: {
      featureFlags: featureFlagsTypes.reduce((acc, feature) => {
        acc[feature.type] = company?.featureFlags?.[feature.type] || false
        return acc
      }, {}),
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      await setCompanyDetails({ companyId: company.id, featureFlags: formValues.featureFlags })

      formik.resetForm()
      setShowCompanyFeatureFlagsDrawer(false)
      setTimeout(() => refetchCompany(), 200)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <FormControl component="fieldset" error={formik.touched.featureFlags && Boolean(formik.errors.featureFlags)}>
          <FormGroup>
            {featureFlagsTypes.map((feature) => (
              <FormControlLabel key={feature.id} control={<Checkbox name={`featureFlags.${feature.type}`} checked={formik.values.featureFlags[feature.type]} onChange={formik.handleChange} onBlur={formik.handleBlur} />} label={feature.name} />
            ))}
          </FormGroup>
          {formik.touched.featureFlags && formik.errors.featureFlags && <div>{formik.errors.featureFlags}</div>}
        </FormControl>
      </form>
    </Box>
  )
}

CompanyFeatureFlagsDrawer.defaultProps = {
  onSubmitEvent: undefined,
  company: undefined,
  refetchCompany: undefined,
}

CompanyFeatureFlagsDrawer.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    featureFlags: PropTypes.instanceOf(Object),
  }),
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowCompanyFeatureFlagsDrawer: PropTypes.func.isRequired,
  refetchCompany: PropTypes.instanceOf(Object),
}

export default CompanyFeatureFlagsDrawer
