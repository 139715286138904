import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import PropTypes from "prop-types"
import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import CustomPagination from "../../../../../custom-pagination"
import EnhancedTableHead from "../../../../../enhanced-table-head"
import OrderProps from "../../../../../../services/order/model"
import InvoiceRow from "../invoice-row"
import { selectSortedPaginatedInvoices, selectTotalInvoices, setOrder, setOrderBy, setPage, setRowsPerPage } from "../../../../../../reducers/invoicesReducer"

function AllInvoiceList({ invoices }) {
  const { t } = useTranslation("invoices")
  const theme = useTheme()
  const dispatch = useDispatch()

  const sortedPaginatedInvoices = useSelector(selectSortedPaginatedInvoices)
  const invoiceLength = useSelector(selectTotalInvoices)
  const { order, orderBy, page, rowsPerPage } = useSelector((state) => state.selectedInvoices)

  const headCells = [
    {
      id: "selected",
      disablePadding: false,
      label: "",
    },
    {
      id: "invoiceNumber",
      disablePadding: false,
      label: t("invoicesTable.headers.invoiceNumber"),
    },
    {
      id: "paymentStatus",
      disablePadding: false,
      label: t("invoicesTable.headers.paymentStatus"),
    },
    {
      id: "createdDate",
      disablePadding: false,
      label: t("invoicesTable.headers.invoiceDate"),
    },
    {
      id: "dueDate",
      disablePadding: false,
      label: t("invoicesTable.headers.dueDate"),
    },
    {
      id: "user.clientAddress.companyName",
      disablePadding: false,
      label: t("invoicesTable.headers.client"),
    },
    {
      id: "summary.invoice.total",
      disablePadding: false,
      label: t("invoicesTable.headers.invoiceTotal"),
    },
    {
      id: "summary.amountPaid",
      disablePadding: false,
      label: t("invoicesTable.headers.totalPaid"),
    },
    {
      id: "summary.amountOutstanding",
      disablePadding: false,
      label: t("invoicesTable.headers.totalUnpaid"),
    },
    {
      id: "billingType",
      disablePadding: false,
      label: t("invoicesTable.headers.billingType"),
    },
    {
      id: "sentByEmail",
      disablePadding: false,
      label: t("invoicesTable.headers.emailStatus"),
    },
    { id: "menu", hideSortIcon: true },
  ]

  const handleRequestSort = (_, property) => {
    if (!property) return
    const isAsc = orderBy === property && order === "asc"
    dispatch(setOrder(isAsc ? "desc" : "asc"))
    dispatch(setOrderBy(property))
  }

  return (
    <Box sx={{ width: "auto" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table size="small" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1.main }}>
            <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>{invoices && sortedPaginatedInvoices.map((invoice) => <InvoiceRow key={invoice.id} invoice={invoice} />)}</TableBody>
          </Table>
        </TableContainer>
        {invoices && (
          <CustomPagination
            count={Math.ceil(invoiceLength / rowsPerPage)}
            page={page}
            setPage={(newPage) => dispatch(setPage(newPage))}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(newRowsPerPage) => dispatch(setRowsPerPage(newRowsPerPage))}
            showFirstButton
            showLastButton
          />
        )}
      </Paper>
    </Box>
  )
}

AllInvoiceList.propTypes = {
  invoices: PropTypes.arrayOf(OrderProps).isRequired,
}

export default AllInvoiceList
