import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

// Others
import AddCreditDrawer from "../../../add-credit-drawer"
import AuditedOrderList from "../audited-order-list"
import MoreMenu from "../../../../../more-menu"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { useCreateCompanyInvoiceMutation, useGetInvoicesCandidatesCompanyQuery, useGetInvoicePreviewMutation } from "../../../../slice"
import SoeConfirmationDialog from "../../../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogContainer from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogHeader from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"
import SoeConfirmationDialogContent from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogActions from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"

import BILLING_TYPE_ENUM from "../../../../../../utils/billing"

function UserGroup({ company, currency, candidates, refetchCandidates }) {
  const { t } = useTranslation("invoices")
  const companyId = company.id || company.pk?.replace("org#", "")

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
  const [onSubmitEvent, setSubmitEvent] = useState()
  const [showCreditDrawer, setShowCreditDrawer] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [isCreditCreating, setIsCreditCreating] = useState(false)

  const { data: auditedOrders, isFetching, refetch } = useGetInvoicesCandidatesCompanyQuery({ companyId, currency }, { skip: !isAccordionExpanded })

  const [createCompanyInvoice, { isLoading }] = useCreateCompanyInvoiceMutation({
    companyId,
    currency,
  })

  const [getInvoicePreview] = useGetInvoicePreviewMutation()

  const handleAccordionChange = (_, expanded) => {
    setIsAccordionExpanded(expanded)
  }

  const handleInvoiceMenuClick = () => {
    setShowConfirmationDialog(true)
  }

  const handlePreviewMenuClick = async () => {
    try {
      const response = await getInvoicePreview({ companyId, currency }).unwrap()
      if (response.nonDTInvoice && response.nonDTInvoice.headers.Location) {
        window.open(response.nonDTInvoice.headers.Location, "_blank")
      }

      if (response.dtInvoice && response.dtInvoice.headers.Location) {
        window.open(response.dtInvoice.headers.Location, "_blank")
      }
    } catch (error) {
      console.error("Error fetching invoice previews:", error)
    }
  }

  const handleAddCreditClick = () => {
    setSubmitEvent(undefined)
    setShowCreditDrawer(true)
  }

  const handleClose = () => {
    setShowConfirmationDialog(false)
  }

  const handleConfirm = () => {
    createCompanyInvoice({ companyId, currency }).then(() => {
      setShowConfirmationDialog(false)
    })
  }

  const sideMenuOptionList = [
    {
      label: t("invoicesStatus.readyToInvoice.userGroup.sideMenu.invoice"),
      handleClick: handleInvoiceMenuClick,
    },
    {
      label: t("invoicesStatus.readyToInvoice.userGroup.sideMenu.preview"),
      handleClick: handlePreviewMenuClick,
    },
    {
      label: t("invoicesStatus.readyToInvoice.userGroup.sideMenu.addCredit"),
      handleClick: handleAddCreditClick,
    },
  ]

  return (
    <>
      <Accordion
        onChange={handleAccordionChange}
        sx={(theme) => ({
          border: 0,
          borderTop: `1px solid ${theme.palette.strokeDefault.main}`,
          borderBottom: `1px solid ${theme.palette.strokeDefault.main}`,
        })}
      >
        <AccordionSummary>
          <Grid container alignItems="center">
            <Grid container item alignItems="center" xs={8}>
              <Typography component="div">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Typography noWrap variant="headingH6">
                    <Typography
                      noWrap
                      variant="headingH6"
                      sx={(theme) => ({
                        fontWeight: theme.typography.fontWeightBold,
                      })}
                    >
                      {company.clientAddress.companyName ? company.clientAddress.companyName : company.clientAddress.personName}
                    </Typography>
                    {company.clientAddress.companyName ? ` (${company.clientAddress.personName})` : ""}
                  </Typography>
                </Box>
                <Typography noWrap variant="bodyLarge400">
                  {company.creatorEmail || company.emailAddress}
                </Typography>
              </Typography>
            </Grid>
            <Grid container item justifyContent="flex-end" alignItems="center" xs={4}>
              <Typography
                noWrap
                variant="headingH6"
                sx={(theme) => ({
                  mr: theme.utils.pxToThemeSpacing(10),
                  fontWeight: theme.typography.fontWeightBold,
                })}
              >
                {currency}
              </Typography>
              {candidates?.orderCount > 0 && (
                <Chip
                  label={t("invoicesStatus.readyToInvoice.userGroup.orderCount", {
                    count: candidates?.orderCount,
                  })}
                  color="secondary"
                  size="small"
                  sx={(theme) => ({ mr: theme.utils.pxToThemeSpacing(10) })}
                />
              )}
              {candidates?.additionalChargeCount > 0 && (
                <Chip
                  label={t("invoicesStatus.readyToInvoice.userGroup.additionalChargeCount", {
                    count: candidates?.additionalChargeCount,
                  })}
                  color="secondary"
                  size="small"
                  sx={(theme) => ({ mr: theme.utils.pxToThemeSpacing(10) })}
                />
              )}
              {candidates?.thirdPartyOrdersCount > 0 && (
                <Chip
                  label={t("invoicesStatus.readyToInvoice.userGroup.thirdPartyOrderCount", {
                    count: candidates?.thirdPartyOrdersCount,
                  })}
                  color="info"
                  size="small"
                  sx={(theme) => ({ mr: theme.utils.pxToThemeSpacing(10) })}
                />
              )}
              {candidates?.creditCount > 0 && (
                <Chip
                  label={t("invoicesStatus.readyToInvoice.userGroup.creditCount", {
                    count: candidates?.creditCount,
                  })}
                  color="warning"
                  size="small"
                  sx={(theme) => ({
                    mr: theme.utils.pxToThemeSpacing(10),
                    color: "white",
                  })}
                />
              )}
              {company?.credit?.total?.amount > 0 && (
                <Chip
                  label={`${t("invoicesStatus.readyToInvoice.userGroup.readyToBeApplied")} ${company?.credit?.total?.amount}  ${company?.credit?.total?.currency}`}
                  color="success"
                  size="small"
                  sx={(theme) => ({
                    mr: theme.utils.pxToThemeSpacing(10),
                    color: "#fff",
                  })}
                />
              )}
              {company.billing?.type === BILLING_TYPE_ENUM.ACCOUNT && (
                <Chip
                  label={t(`invoicesStatus.readyToInvoice.userGroup.${company?.billing?.type}`)}
                  size="small"
                  color="primary"
                  sx={(theme) => ({
                    color: theme.palette.contentNeutralPrimaryReverse.main,
                  })}
                />
              )}
              {company.billing?.type === BILLING_TYPE_ENUM.CREDIT_CARD && (
                <Chip
                  label={t(`invoicesStatus.readyToInvoice.userGroup.${company?.billing?.type}`)}
                  size="small"
                  color="secondary"
                  sx={(theme) => ({
                    color: theme.palette.contentNeutralPrimaryReverse.main,
                  })}
                />
              )}
              <MoreMenu optionsList={sideMenuOptionList} />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {!auditedOrders || isFetching ? <Skeleton variant="rectangular" width="100%" height={100} /> : <AuditedOrderList refetchCredits={refetch} refetchCandidates={refetchCandidates} auditedOrders={auditedOrders} maxRows={5} />}
        </AccordionDetails>
      </Accordion>
      <SoeDrawer showDrawer={showCreditDrawer} setShowDrawer={setShowCreditDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("addCreditDrawer.title")}
            setShowDrawer={() => {
              setShowCreditDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <AddCreditDrawer onSubmitEvent={onSubmitEvent} setShowCreditDrawer={setShowCreditDrawer} setIsCreditCreating={setIsCreditCreating} clientEmailAddress={company.emailAddress} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("addCreditDrawer.actions.save.label"),
                variant: "contained",
                disabled: isCreditCreating,
                loading: isCreditCreating,
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title={t("invoiceConfirmationDialog.title")}
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {t("invoiceConfirmationDialog.content")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: handleClose,
                label: t("invoiceConfirmationDialog.actions.cancel"),
                variant: "outlined",
                disabled: isLoading,
              },
              {
                action: handleConfirm,
                label: t("invoiceConfirmationDialog.actions.confirm"),
                variant: "contained",
                loading: isLoading,
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
    </>
  )
}

UserGroup.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    pk: PropTypes.string,
    creatorEmail: PropTypes.string,
    emailAddress: PropTypes.string,
    companyId: PropTypes.string,
    clientAddress: PropTypes.shape({
      personName: PropTypes.string,
      companyName: PropTypes.string,
      emailAddress: PropTypes.string,
    }),
    credit: PropTypes.shape({
      total: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
    }),
    billing: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
  candidates: PropTypes.shape({
    additionalChargeCount: PropTypes.number,
    thirdPartyOrdersCount: PropTypes.number,
    creditCount: PropTypes.number,
    orderCount: PropTypes.number,
  }).isRequired,
  currency: PropTypes.string.isRequired,
  refetchCandidates: PropTypes.func,
}

UserGroup.defaultProps = {
  refetchCandidates: () => {},
}

export default UserGroup
