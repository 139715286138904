import { createSlice } from "@reduxjs/toolkit"
import getComparator from "../utils/sorting"
import { INVOICES_STATUS } from "../constant"

const initialState = {
  invoices: [],
  searchFilteredInvoices: null,
  selectedInvoiceIds: [],
  order: "desc",
  orderBy: "invoiceNumber",
  page: 1,
  rowsPerPage: 15,
  filterStatus: INVOICES_STATUS.ALL_INVOICES,
}

const MAX_SELECTABLE_INVOICES = 10

const filterInvoicesByStatus = (invoices, status) => {
  switch (status) {
    case INVOICES_STATUS.UNPAID_INVOICES:
      return invoices.filter((invoice) => invoice.paymentStatus === "pending")
    case INVOICES_STATUS.PAID_INVOICES:
      return invoices.filter((invoice) => invoice.paymentStatus === "paid")
    case INVOICES_STATUS.ALL_INVOICES:
    default:
      return invoices
  }
}

const selectedInvoicesSlice = createSlice({
  name: "selectedInvoices",
  initialState,
  reducers: {
    setInvoices(state, action) {
      return {
        ...state,
        invoices: action.payload,
        searchFilteredInvoices: null, // Reset search results when setting new invoices
      }
    },
    setSearchFilteredInvoices(state, action) {
      state.searchFilteredInvoices = action.payload
      state.page = 1
    },
    clearSearchFilter(state) {
      state.searchFilteredInvoices = null
      state.page = 1
    },
    selectInvoice(state, action) {
      if (!state.selectedInvoiceIds.includes(action.payload) && state.selectedInvoiceIds.length < MAX_SELECTABLE_INVOICES) {
        state.selectedInvoiceIds.push(action.payload)
      }
    },
    deselectInvoice(state, action) {
      state.selectedInvoiceIds = state.selectedInvoiceIds.filter((id) => id !== action.payload)
    },
    clearSelection(state) {
      state.selectedInvoiceIds = []
    },
    selectAll(state) {
      const baseInvoices = state.searchFilteredInvoices || state.invoices
      const filteredInvoices = filterInvoicesByStatus(baseInvoices, state.filterStatus)
      const sortedInvoices = filteredInvoices
        .slice()
        .sort(getComparator(state.order, state.orderBy))
        .slice(state.page * state.rowsPerPage - state.rowsPerPage, state.page * state.rowsPerPage)
      const firstTenInvoices = sortedInvoices.slice(0, MAX_SELECTABLE_INVOICES)
      state.selectedInvoiceIds = firstTenInvoices.map((invoice) => invoice.id)
    },
    setOrder(state, action) {
      state.order = action.payload
    },
    setOrderBy(state, action) {
      state.orderBy = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload
      state.page = 1
    },
    setFilterStatus(state, action) {
      state.filterStatus = action.payload
      state.searchFilteredInvoices = null
      state.page = 1
    },
  },
})

export const selectSelectedInvoices = (state) => {
  const { invoices, searchFilteredInvoices, selectedInvoiceIds, filterStatus } = state.selectedInvoices
  const baseInvoices = searchFilteredInvoices || invoices
  const filteredInvoices = filterInvoicesByStatus(baseInvoices, filterStatus)
  return filteredInvoices.filter((invoice) => selectedInvoiceIds.includes(invoice.id))
}

export const selectIsMaxInvoicesSelected = (state) => {
  const { selectedInvoiceIds } = state.selectedInvoices
  return selectedInvoiceIds.length >= MAX_SELECTABLE_INVOICES
}

export const selectSortedPaginatedInvoices = (state) => {
  const { invoices, searchFilteredInvoices, order, orderBy, page, rowsPerPage, filterStatus } = state.selectedInvoices
  const baseInvoices = searchFilteredInvoices || invoices
  if (!baseInvoices || !Array.isArray(baseInvoices) || baseInvoices.length === 0) return []

  const filteredInvoices = filterInvoicesByStatus(baseInvoices, filterStatus)

  return filteredInvoices
    .slice()
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
}

export const selectTotalInvoices = (state) => {
  const { invoices, searchFilteredInvoices, filterStatus } = state.selectedInvoices
  const baseInvoices = searchFilteredInvoices || invoices
  const filteredInvoices = filterInvoicesByStatus(baseInvoices, filterStatus)
  return filteredInvoices?.length || 0
}

export const { setInvoices, setSearchFilteredInvoices, clearSearchFilter, selectInvoice, deselectInvoice, clearSelection, selectAll, setOrder, setOrderBy, setPage, setRowsPerPage, setFilterStatus } = selectedInvoicesSlice.actions

export default selectedInvoicesSlice.reducer
