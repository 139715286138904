import Card from "@mui/material/Card"
import React from "react"
import Skeleton from "@mui/material/Skeleton"

import PropTypes from "prop-types"
import AllInvoiceList from "./components/all-invoice-list"
import OrderProps from "../../../../services/order/model"

function AllInvoices({ invoices, isFetching }) {
  return (
    <Card variant="elevation" square sx={(theme) => ({ borderTop: `1px solid ${theme.palette.strokeDefault.main}` })}>
      {!invoices || isFetching ? <Skeleton variant="rectangular" width="100%" height={100} /> : <AllInvoiceList invoices={invoices} />}
    </Card>
  )
}

AllInvoices.defaultProps = {
  invoices: [],
  isFetching: true,
}

AllInvoices.propTypes = {
  invoices: PropTypes.arrayOf(OrderProps),
  isFetching: PropTypes.bool,
}

export default AllInvoices
