import frLocale from "date-fns/locale/fr"
import enLocale from "date-fns/locale/en-US"

export const LOCALES = {
  fr: frLocale,
  en: enLocale,
}

export const LANGUAGES = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "fr",
    name: "Français",
  },
]

export const ROUTES = {
  HOME: "/",
  DEMO: "/demo",
  DEMO_BUTTON: "/demo/button",
  DEMO_ICONS: "/demo/icons",
  DEMO_TYPOGRAPHY: "/demo/typography",
  DEMO_INPUTS: "/demo/inputs",
  DEMO_ALERTS: "/demo/alerts",
  DEMO_SURFACE: "/demo/surface",
  DEMO_DATA_DISPLAY: "/demo/data-display",
  DEMO_DATA_GRID: "/demo/data-grid",
  USERS: "/users",
  CLIENTS: "/clients",
  COMPANIES: "/companies",
  ORDERS: "/orders",
  READY_TO_INVOICE: "/ready-to-invoice",
  INVOICES: "/invoices",
  AUDITS: "/audits",
  CARRIERS: "/carriers",
  PRICING: "/pricing",
  SETTINGS: "/settings",
  USER_PROFILE: "/user-profile",
  BULK: "/bulk",
}

export const AddressTypeEnum = {
  Shipping: "shipping",
}

export const AddressSearchGroupsText = {
  CanadaPost: "CanadaPost",
}

export const UNIT_OF_WEIGHT = {
  POUND: "lb",
  KILOGRAM: "kg",
}

export const INVOICES_STATUS = {
  UNPAID_INVOICES: "unpaidInvoices",
  PAID_INVOICES: "paidInvoices",
  ALL_INVOICES: "allInvoices",
}
